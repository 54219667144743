import { ref } from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useFinancialHeadings() {

    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]); //ref: to make it reactive
    const financial_headings = ref([]); //ref: to make it reactive
    const financial_heading = ref([]); //ref: to make it reactive

    const getFinancialHeadings = async () => {
        try {
            let response = await axios.get('/api/financial_headings');
            financial_headings.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getFinancialHeading = async (id) => {
        try {
            let response = await axios.get('/api/financial_headings/' + id);
            financial_heading.value = response.data.data;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeFinancialHeading = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/financial_headings/', data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const deleteFinancialHeading = async (id) => {
        try {
            await axios.delete('/api/financial_headings/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }

    const updateFinancialHeading = async (data, id) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/financial_headings/' + id, data);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    return {
        financial_headings,
        financial_heading,
        storeFinancialHeading,
        getFinancialHeadings,
        getFinancialHeading,
        deleteFinancialHeading,
        updateFinancialHeading,
        msgs,
        errors,
    }
}