<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Headings</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }">Financial</router-link>
          </li>
          <li class="breadcrumb-item active">Headings</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <button
              v-can="'add_financial_headings'"
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModalCenter"
              onclick="this.blur();"
              @click="resetFields"
            >
              Add New
            </button>
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Updated By</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="financial_headings.length">
                  <tr v-for="heading in financial_headings" :key="heading.id">
                    <td>{{ heading.name }}</td>
                    <td>{{ heading.created_at }}</td>
                    <td>{{ heading.created_by }}</td>
                    <td>{{ heading.updated_by }}</td>
                    <td>
                      <span>
                        <a
                          v-can="'edit_financial_headings'"
                          href="javascript:void()"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          title="Edit"
                          @click="editHeading(heading)"
                          class="mr-4"
                          onclick="this.blur();"
                        >
                          <i class="fa fa-pencil color-muted"></i>
                        </a>
                        <a
                          v-can="'delete_financial_headings'"
                          @click.prevent="deleteHeading(heading.id)"
                          href="javascript:void()"
                          title="Delete"
                          onclick="this.blur();"
                          ><i class="fa fa-close color-danger"></i
                        ></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="5">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Financial Heading
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <form>
              <div class="form-group">
                <label for="message-text" class="col-form-label">Name:</label>
                <input
                  class="form-control"
                  required
                  id="message-text"
                  placeholder="Heading's Name"
                  v-model="heading_name"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="close"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="manageHeading()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useFinancialHeadings from "@/composables/financial_headings";
import { onMounted, inject, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FeedbackMessages,
  },
  setup() {
    const heading_id = ref(0);
    const heading_name = ref("");
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_financial_headings"))
        router.push({
          name: "401",
        });
    });

    const {
      errors,
      financial_headings,
      getFinancialHeadings,
      deleteFinancialHeading,
      storeFinancialHeading,
      updateFinancialHeading,
    } = useFinancialHeadings();

    onMounted(() => {
      getFinancialHeadings();
    });

    const deleteHeading = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteFinancialHeading(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getFinancialHeadings();
            });
          }
        });
    };

    const manageHeading = async () => {
      if (heading_id.value == 0) {
        storeFinancialHeading({ name: heading_name.value }).then((resp) => {
          if (resp != "fail") {
            document.getElementById("close").click();
            swal.fire("Stored!", resp, "success");
            getFinancialHeadings();
          }
        });
      } else {
        updateFinancialHeading({ name: heading_name.value , "_method": 'PATCH'},  heading_id.value).then(
          (resp) => {
            if (resp != "fail") {
              document.getElementById("close").click();
              swal.fire("Stored!", resp, "success");
              getFinancialHeadings();
            }
          }
        );
      }
    };

    const editHeading = async (heading) => {
      heading_id.value = heading.id;
      heading_name.value = heading.name;
    };
    
    const resetFields = async () => {
      heading_id.value = 0;
      heading_name.value = '';
    };

    return {
      errors,
      heading_name,
      financial_headings,
      deleteHeading,
      manageHeading,
      editHeading,
      resetFields
    };
  },
};
</script>